import {
  ChangeEventHandler,
  forwardRef,
  HTMLProps,
  KeyboardEventHandler,
  memo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { getFittingFontSize, getTextAreaHeight, MAX_FONT_SIZE, MIN_FONT_SIZE } from './name.utils';
import { useCommandDispatch } from '@xspecs/design-system';
import { useMergedRef, useThrottledCallback } from '@mantine/hooks';

type Name2Props = {
  entityId: string;
  name: string;
  fontSize: number;
  isSelected: boolean;
  isNew: boolean;
} & HTMLProps<HTMLDivElement>;

const _Name2 = forwardRef<HTMLDivElement, Name2Props>((props, ref) => {
  const { name: incomingName, entityId, fontSize, isSelected, isNew, ...rest } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const mergedContainerRef = useMergedRef(containerRef, ref);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const { dispatchCommand } = useCommandDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateName = useCallback(
    useThrottledCallback(
      (newName: string, fontSize: number) =>
        dispatchCommand('RenameEntityCommand', {
          entityId,
          newName,
          fontSize,
        }),
      1000,
    ),
    [],
  );

  const onChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(() => {
    const result = updateStyles(containerRef.current!, textAreaRef.current!);
    updateName(textAreaRef.current?.value || '', result?.fontSize ?? MIN_FONT_SIZE);
  }, [updateName]);

  const isSetRef = useRef(false);
  useEffect(() => {
    const isNotMyChange = document.activeElement !== textAreaRef.current;
    if (isNotMyChange) {
      isSetRef.current = false;
    }

    if (textAreaRef.current && !isSetRef.current) {
      isSetRef.current = true;
      textAreaRef.current.value = incomingName;
      updateStyles(containerRef.current!, textAreaRef.current!, fontSize);
    }
  }, [incomingName, fontSize]);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLTextAreaElement>>((event) => {
    if (event.key === 'Escape') {
      textAreaRef.current?.blur();
    }
  }, []);

  // Focus if new
  useEffect(() => {
    if (isNew) {
      textAreaRef.current?.focus();
    }
  }, [isNew]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (fontSize === -1) {
      timeout = setTimeout(() => {
        const result = updateStyles(containerRef.current!, textAreaRef.current!);
        dispatchCommand('SetEntityAttributesCommand', {
          entityId,
          attributeName: 'fontSize',
          attributeValue: result.fontSize,
        });
      }, 300);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [dispatchCommand, entityId, fontSize, updateName]);

  return (
    <div
      ref={mergedContainerRef}
      className="absolute top-[5px] left-[5px] h-[calc(100%-10px)] w-[calc(100%-10px)] rounded-md"
      {...rest}
    >
      {!isSelected ? <div className="size-full absolute"></div> : null}
      <textarea
        ref={textAreaRef}
        className={'nodrag node-font hide-scrollbar size-full outline-none resize-none content-center text-center'}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
});
_Name2.displayName = 'Name2';

export const Name2 = memo(_Name2);

function updateStyles(container: HTMLDivElement, textArea: HTMLTextAreaElement, incomingFontSize?: number) {
  const needsFontRecompute = incomingFontSize === undefined || incomingFontSize === -1;

  // if (needsFontRecompute) console.log('Needs font recompute:', needsFontRecompute);
  const value = textArea.value;
  const fontSize = needsFontRecompute
    ? getFittingFontSize(value, textArea, container, MAX_FONT_SIZE, MIN_FONT_SIZE)
    : incomingFontSize;
  const textAreaHeight = getTextAreaHeight(container, textArea);

  textArea.style.fontSize = `${fontSize}px`;
  textArea.style.height = `${textAreaHeight}px`;

  return {
    fontSize,
    textAreaHeight,
  } as const;
}
